import React from "react"
import Layout from '../components/layout'
import SEO from "../components/seo"

import {Container, Row, Col} from "react-bootstrap"

const Interiordesignenglish = () => (
  <Layout>
  <SEO title="Interior Design" keyword={['Interior','Design','Interior Design']} />
  <Container>
    <h1>How to start with Interior Design Work</h1>
    <Row>
    <p>
      <p>If you want to design the interior of your home, want to give a luxury look to your home but you cannot understand where to start from, what design you need to keep in mind, which design is best for you
 If you want to know all these things for the house
So you will get all this information through this website</p>

<p><b>Planning</b></p>
<p>First of all, do a proper planning of the budget

Suppose your maximum budget is 6 lakhs and you cannot spend more money than that, then you should not plan more than 6 lakhs in the beginning. It will automatically reach 6 lakhs
After that, whatever work you want to do, whatever is required, divide it into 2 parts.
One that is important, you should plan it first and what is optional you do according to the budget</p>

<p>Now you have to decide what is necessary for you and what is optional
These are some things that will come under the minimum requirement. Woodwork, kitchen related items such as sinks, granite electrical items such as fan light plumbing items etc.
After that you have to finalize the design
The role of design is the most important. Properly design whatever you want to get done


If you do not design and you start working, then what is the problem that what you thought was not there, you know that it was your imagination that could not turn into reality. So for that you have 3 options
 Learn 3D Design If you have a little computer knowledge, then you can learn 3D design very well in 8 to 10 days through YouTube. There are many software in the market, you can use Sketchup or Blender ka.


 If you do not have the basic knowledge of computer or you live in a very remote area where you cannot get your interior designer done, then there is no problem, you can see 2D design on your paper by yourself.
If you have a shortage of time, then you can hire a professional, he can tell you the design in a proper way, how will you look</p>
<p><b>Wood Selection</b></p>
<p>When the design finishes the budget recording then you have to choose the wood. You should decide which wood should be used for whom, it is very important, then you can take details by clicking on this link.</p>
<p><b>Laminate Selection</b></p> 
After that you have to choose the laminate. You have a lot of options for laminates like Decolam, PVC, Acrylic laminates and veneers. You can do any of these selections according to your own

5) When you have finished everything, if you want to start work, then you must use a floor protection sheet. This is a very small thing, but it has practical purpose, it can protect your floors properly.
6) False Ceiling The role of False Ceiling is very important in giving a luxury look to a home. If you change the look of your entire house, then you will also have to plan for details if your budget supports it.

4) Kitchen Design - A good contribution to the look of a house is due to the kitchen, so you should plan the kitchen properly, plan all the planning of sink, granite, chimney hob etc. before planning.
4) Electrical and plumbing work - When woodwork and False Ceiling are done, you can get electrical and plumbing work done.

4) When everything is done, you can start painting work
10) Deep painting after painting
</p>
    </Row>
  </Container>
</Layout>
  )

  
export default Interiordesignenglish
